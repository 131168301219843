<template>
<div class="content-2">
  <div class="content-2-1">
    RESEARCH COMMUNITY
  </div>
  <div class="content-2-2" id="research">
    科研共同体
    <div class="right-hr"></div>
  </div>
  <div class="content-2-3">
    3QLAB科研共同体拥有实验室行业新三板上市公司——<span class="brod">华盛科技控股股份有限公司</span>
  </div>
  <div class="content-2-4">
    <div class="block block_1">
      <div class="img"></div>
      <div class="text">其拥有<span class="brod">364</span>项实验室专利</div>
    </div>
    <div class="block block_2">
      <div class="img"></div>
      <div class="text"><span class="brod">13</span>项全行业资质</div>
    </div>
    <div class="block block_3">
      <div class="img"></div>
      <div class="text"><span class="brod">17000+</span>科学家数据库</div>
    </div>
    <div class="block block_4">
      <div class="img"></div>
      <div class="text"><span class="brod">20000+</span>实验室客户</div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  
}
</script>

<style scoped lang="scss">
.content-2{
  font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
  width: 1200px;
  height: 523px;
  margin: 0 auto;
  position: relative;
  //background-color: antiquewhite;
  .content-2-1{
    font-size: 16px;
    color: #A3A3A3;
    width: 100%;
  }
  .content-2-2{
    font-size: 30px;
    color: #fff;
    font-weight: bold;
    margin-top: 20px;
    width: 100%;
    height: 40px;
    position: relative;
    .right-hr{
      width: 1000px;
      height: 1px;
      position: absolute;
      border: 0;
      padding-top: 1px;
      background: linear-gradient(to right, transparent, #d0d0d5);
      right: 0;
      top: 17px;
    }
  }
  .content-2-3{
    width: 100%;
    font-size: 26px;
    color: #fff;
    height: 40px;
    margin-top: 20px;
    .brod{
      color: #584298;
      font-size: 35px;
      margin-left: 14px;
    }
  }

  .content-2-4{
    width: 100%;
    height: 300px;
    margin-top: 20px;
    position: relative;
    .block{
      width: 200px;
      height: 150px;
      font-size: 16px;
      position: absolute;
      .text{
        text-align: center;
        position: absolute;
        width: 100%;
        bottom: 10px;
        color: #fff;
        .brod{
          color: #584298;
          font-size: 20px;
          margin-left: 10px;
          margin-right: 10px;
          font-weight: bold;
        }
      }

    }

    @mixin imgBlock{
      position: absolute;
      width: 100px;
      height: 100px;
      background-size: 100% 100%;
      left: 50%;
      margin-left: -50px;
    }

    .block_1{
      left: 0;
      top: 50px;
      .img{
        @include imgBlock;
        background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/ChessGame/content-2-4-1.png");
      }
    }

    .block_2{
      left: 300px;
      top: 110px;
      .img{
        @include imgBlock;
        background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/ChessGame/content-2-4-2.png");
      }
    }

    .block_3{
      left: 600px;
      top: 50px;
      .img{
        @include imgBlock;
        background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/ChessGame/content-2-4-3.png");
      }
    }

    .block_4{
      left: 900px;
      top: 110px;
      .img{
        @include imgBlock;
        background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/ChessGame/content-2-4-4.png");
      }
    }
  }
}
</style>