<template>
<div class="content_6">
  <div class="content-6-1">
    ART COMMUNITY
  </div>
  <div class="content-6-2" id="art">
    艺术共同体
    <div class="right-hr"></div>
  </div>
  <div class="content-6-3">
    <p>3QLAB艺术产业</p>
    拥有集影视策划、制作、发行、营销宣传、演艺经纪于一体的优秀传媒影视公司——盛天传媒
  </div>
  <div class="content-6-4">
    <div class="img"></div>
    <div class="main">自成立来制作发行了《天道》、《马大帅》系列、《守望的天空》等大量优秀影视作品，多次获得“五个一“工程奖、金鹰奖等，同时拥有知名制作人林子、著名演员葛优等优秀股东背景。</div>
  </div>
  <div class="content-6-5">
    <div class="bg" v-bind:class="{open:link_1}"></div>
    <div class="content-6-5-1" v-bind:class="{open:link_2}"></div>
    <div class="content-6-5-2" v-bind:class="{open:link_3}"></div>
    <div class="content-6-5-3" v-bind:class="{open:link_4}"></div>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      link_1: false,
      link_2: false,
      link_3: false,
      link_4: false
    }
  },
  mounted() {
    window.addEventListener("scroll", this.scrollHandle, true); // 监听 监听元素是否进入/移出可视区域
  },
  methods: {
    scrollHandle() {
      const offset = this.$el.getBoundingClientRect();
      const offsetTop = offset.top;
      const offsetBottom = offset.bottom;

      if (offsetTop <= (window.innerHeight / 4) && offsetBottom >= (window.innerHeight / 2)) {
        this.init()
      }
    },
    init() {
      setTimeout(() => {
        this.link_1 = true
      }, 500)
      setTimeout(() => {
        this.link_2 = true
      }, 1300)
      setTimeout(() => {
        this.link_3 = true
      }, 1800)
      setTimeout(() => {
        this.link_4 = true
      }, 2300)
    }
  }
}
</script>

<style scoped lang="scss">
.content_6{
  width: 1200px;
  margin: 0 auto;
  height: 850px;
  font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
  position: relative;
  .content-6-1{
    font-size: 16px;
    color: #A3A3A3;
    width: 100%;
  }
  .content-6-2{
    font-size: 30px;
    color: #fff;
    font-weight: bold;
    margin-top: 20px;
    width: 100%;
    height: 40px;
    position: relative;
    .right-hr{
      width: 1000px;
      height: 1px;
      position: absolute;
      border: 0;
      padding-top: 1px;
      background: linear-gradient(to right, transparent, #d0d0d5);
      right: 0;
      top: 17px;
    }
  }
  .content-6-3{
    position: absolute;
    color: #fff;
    top: 120px;
    left: 0;
    font-size: 19px;
    font-weight: bold;
  }
  .content-6-4{
    position: absolute;
    left: 60px;
    top: 250px;
    width: 500px;
    height:90px;
    .img{
      width: 59px;
      height: 59px;
      background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/ChessGame/content-6-4.png");
      background-size: 100% 100%;
      position: absolute;
      top: 15px;
    }
    .main{
      width: 429px;
      height: 80px;
      color: #fff;
      font-size: 16px;
      position: absolute;
      top: 0;
      left: 80px;
      line-height: 25px;
    }
  }
  .content-6-5{
    width: 100%;
    height: 390px;
    position: absolute;
    left: 0;
    top: 400px;
    .bg{
      width: 100%;
      height: 170px;
      position: absolute;
      left: 0;
      top: 100px;
      background: linear-gradient(180deg, #3E75E4 0%, #7C5FD7 100%);
      opacity: 0;
      transition: 0.3s all;
    }
    @mixin content-6-Img{
      position: absolute;
      width: 281px;
      height: 390px;
      background-size: 100% 100%;
      top: 0;
      opacity: 0;
      transition: 0.3s all;
    }
    .content-6-5-1{
      @include content-6-Img;
      background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/ChessGame/content-6-1.png");
      left: 50px;
    }
    .content-6-5-2{
      @include content-6-Img;
      background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/ChessGame/content-6-2.png");
      left: 390px;
    }
    .content-6-5-3{
      @include content-6-Img;
      background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/ChessGame/content-6-3.png");
      width: 429px;
      height: 245px;
      left: 730px;
      top: 70px;
    }
  }
}

.open{
  opacity: 1 !important;
}
</style>
