<template>
  <div class="content_5">
    <div class="content-5-1">
      华盛元宇宙
      <div class="hr"></div>
    </div>
    <div class="content-5-2">
      利用华盛科技自身科研与资源吸引力，打造科研+艺术的华盛元宇宙，建设虚拟实验室、VR展厅，举办元宇宙线下展，真正打破线上线下壁垒，打造线上线下共建的元宇宙。
    </div>
    <div class="content-5-3">
     <div class="block"  v-bind:class="{open:link_1}">
       <div class="left">
         <div class="title">蓝山起源</div>
         <div class="title-1">蓝山公主瓦奥莱特</div>
         <div class="main">
           <p>太阳是东方升起的明星，流水奔赴低地的海洋，苍翠的绿色由细胞铸就，大气层被粒子铺满。厚重被微末取代，整洁走向凌乱，不可逆性带给我们回忆和未来。</p>
           <p>开垦蛮荒的勇士在大地上筑起概念的摇篮，千万亿个理想相互争斗着为星球建起文明的堡垒。在宇宙有限的生命里，真实、良质与优美是天际猎猎招展的旌旗，等待着双脚飞翔的人类用双手攥出褶皱。</p>
           <p>只是物质更迭和理想交替的螺旋梯上，总有杂音干扰、断屑阻塞，令人眼花缭乱大脑过载的浮躁感正蚕食文明的阶梯。于是那片桃源乡被人们从海底打捞、从微电路中淬炼、从恣意的线条里抽取。它向梦想家敞开，它为寻宝者锻造桥梁，它被称之为——瓦奥莱特。</p>
           <p>瓦奥莱特藏在任何地方，科学家可以从拓扑的概念中寻得隧道，艺术家能够在抽象的闭环中开启门扉，金融家也曾用奇思异想开辟新的捷径。那是一座奇谲的城市，藏在每个电磁信号能传输到的角落，巨型的实验室置身海底，透明的画坊停在空中，漂浮的文字随意附着在人体。这里没有尘世喧嚣、没有名利纷扰，更没有急功近利的随波逐流，只有天空中倒垂的浩瀚无边的电子森林，如巨大的未知、巨大的神秘、巨大的可能性在俯瞰着每一个人。</p>
           <p>那里是梦想家的集市，里面藏了太多不为人知的宝藏，假说与公理、具象与抽象、供给与产出，无数真相与艺术都可由人们慢慢寻得。瓦奥莱特亦是寻宝人的天堂，任何想象与设计都将在寻宝人的推动和投资下催生出新的硕果。</p>
           <p>瓦奥莱特属于所有人。</p>
           <p>每一位到访者只需要向隐居城中的神秘领主献上自己的知识，就能够获得永久的居住权……以及抚摸领主软绵绵的肉垫。</p>
         </div>
       </div>
       <div class="right">
         <div class="bg"></div>
       </div>
     </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      link_1: false,
    }
  },
  mounted() {
    window.addEventListener("scroll", this.scrollHandle, true); // 监听 监听元素是否进入/移出可视区域
  },
  methods: {
    scrollHandle() {
      const offset = this.$el.getBoundingClientRect();
      const offsetTop = offset.top;
      const offsetBottom = offset.bottom;

      if (offsetTop <= (window.innerHeight / 4) && offsetBottom >= (window.innerHeight / 2)) {
        this.init()
      }
    },
    init() {
      setTimeout(() => {
        this.link_1 = true
      }, 500)
    }
  }
}
</script>

<style scoped lang="scss">
.content_5 {
  width: 1200px;
  margin: 0 auto;
  height: 580px;
  font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
  position: relative;

  .content-5-1 {
    position: absolute;
    color: #fff;
    left: 60px;
    font-size: 25px;

    .hr {
      width: 200px;
      height: 1px;
      position: absolute;
      border: 0;
      padding-top: 1px;
      left: 140px;
      top: 17px;
      background: linear-gradient(to left, transparent, #d0d0d5);
    }
  }

  .content-5-2 {
    color: #ffffff;
    width: 370px;
    line-height: 30px;
    letter-spacing: 3px;
    position: absolute;
    left: 80px;
    top: 50px;
  }

  .content-5-3 {
    width: 608px;
    height: 485px;
    position: absolute;

    left: 600px;

    .block{
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: 2s all;
      left: -100%;
      background-color: #fff;
      border-radius: 13px;
    }
    .open{
      left: 0;
      opacity: 1;
    }

    .left {
      position: absolute;
      left: 0;
      top: 0;
      width: 260px;
      height: 100%;
      background: linear-gradient(180deg, #3E75E4 0%, #7C5FD7 100%);
      border-top-left-radius: 13px;
      border-bottom-left-radius: 13px;

      .title {
        position: absolute;
        color: #fff;
        left: 20px;
        top: 20px;
        font-size: 16px;
      }

      .title-1 {
        color: #fff;
        font-size: 23px;
        font-weight: bold;
        width: 100px;
        position: absolute;
        left: 20px;
        top: 50px;
      }

      .main {
        position: absolute;
        color: #fff;
        font-size: 15px;
        width: 217px;
        height: 330px;
        overflow-y: auto;
        left: 20px;
        top: 120px;
        line-height: 25px;
        p{
          text-indent: 1rem;
        }
      }

      .main::-webkit-scrollbar {
        /*滚动条整体样式*/
        display: none;
      }

      .main::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        display: none;
      }

      .main::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        display: none;
      }
    }

    .right{
      width: 317px;
      height: 423px;
      position: absolute;
      left: 275px;
      top: 40px;
      background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/ChessGame/content-5-3-right.jpg");
      background-size: 100% 100%;
    }
  }
}
</style>
