<template>
<div class="content_7">
  <div class="content-7-1">
    影视制作团队资源
    <div class="hr"></div>
  </div>
  <div class="content-7-2">
    <div class="main">
      <div class="content-7-2-1">
        <p>盛天传媒全资子公司</p>
        <div class="hr"></div>
        <p>北京天润传媒有限公司</p>
        <p>文澜视讯（天津）音视技术发展有限公司</p>
        <p>杭州动蛙数码科技有限公司</p>
      </div>
      <div class="content-7-2-2"></div>
      <div class="content-7-2-3">
        3QLAB打造以盛天传媒为中心的艺术生态社区拥有着优秀的影视IP资源和专业的影视制作团队
      </div>
      <div class="content-7-2-4"></div>
      <div class="content-7-2-5">
        盛天传媒拥有北京天润传媒有限公司、文澜视讯（天津）音视技术发展有限公司、金麦浪等全资子公司，形成了产品、渠道、品牌全方位的产业体系，同时与中影集团、星美集团、博纳国际影业、阿里影业、横店集团以及欧美多个顶级概念设计、影视特效、VR技术团队建立了长期的战略合作关系。
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
.content_7{
  width: 1200px;
  margin: 0 auto;
  height: 750px;
  font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
  position: relative;

  .content-7-1 {
    position: absolute;
    color: #fff;
    left: 60px;
    font-size: 25px;

    .hr {
      width: 200px;
      height: 1px;
      position: absolute;
      border: 0;
      padding-top: 1px;
      left: 230px;
      top: 17px;
      background: linear-gradient(to left, transparent, #d0d0d5);
    }
  }
  .content-7-2{
    width: 100%;
    height: 586px;
    background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/ChessGame/content-7-1.png");
    background-size: 100% 100%;
    position: absolute;
    left: 0;
    top: 80px;
    .main{
      background-color: rgba(124, 95, 215, 1);
      width: 540px;
      height: 586px;
      margin: 0 auto;
      position: relative;
      .content-7-2-1{
        position: absolute;
        width: 395px;
        height: 183px;
        border:1.5px solid #fff;
        border-radius: 13px;
        left: 50%;
        margin-left: -197.5px;
        top: 40px;
        .hr{
          width: 330px;
          height: 2px;
          background-color: #fff;
          margin: 0 auto;
        }
        p{
          width: 100%;
          color: #ffffff;
          text-align: center;
        }
      }
      .content-7-2-2{
        position: absolute;
        width: 440px;
        height: 2px;
        background-color: #fff;
        left: 50%;
        margin-left: -220px;
        top: 270px;
      }
      .content-7-2-3{
        position: absolute;
        width: 420px;
        color: #ffffff;
        font-size: 20px;
        line-height: 35px;
        left: 50%;
        margin-left: -210px;
        top: 290px;
      }
      .content-7-2-4{
        position: absolute;
        width: 440px;
        height: 2px;
        background-color: #fff;
        left: 50%;
        margin-left: -220px;
        top: 380px;
      }
      .content-7-2-5{
        position: absolute;
        width: 430px;
        color: #ffffff;
        font-size: 13px;
        line-height: 28px;
        left: 50%;
        margin-left: -215px;
        top: 400px;
      }
    }
  }
}
</style>