<template>
  <div class="content_4">
    <div class="bg"></div>
    <div class="content-4-1">
      社区项目
      <div class="hr"></div>
    </div>
    <div class="content-4-2">
      华盛科技利用自身实验室资源优势，构建了标准化设计总装、智能化软硬件管理系统、物联化科研服务为一体的智慧实验室平台，力求全面解决实验室基础建设、运营安全、工作效率等痛点问题，同时打造科学社区生态圈，让人类科学智慧实现共享，里程碑式推动科技发展进程。
    </div>
    <div class="content-4-3">
      <div class="content-4-3-1">
        <div class="block" v-bind:class="{open:link_1}">
          <div class="number">01</div>
          <div class="title">智能自动化仪器：机械臂、自动化实验仪器，提高实验效率</div>
          <div class="img"></div>
        </div>
      </div>
      <div class="content-4-3-2">
        <div class="block" v-bind:class="{open:link_1}">
          <div class="number">02</div>
          <div class="title">花生盒：实验室物联网系统，便捷管理，智能分析</div>
          <div class="img"></div>
        </div>
      </div>
      <div class="content-4-3-3">
       <div class="block" v-bind:class="{open:link_1}">
         <div class="number">03</div>
         <div class="title">iLabPlus：实验室试剂耗材智能管理、电商采购平台</div>
         <div class="img"></div>
       </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      link_1: false
    }
  },
  mounted() {
    window.addEventListener("scroll", this.scrollHandle, true); // 监听 监听元素是否进入/移出可视区域
  },
  methods: {
    scrollHandle() {
      const offset = this.$el.getBoundingClientRect();
      const offsetTop = offset.top;
      const offsetBottom = offset.bottom;

      if (offsetTop <= (window.innerHeight / 4) && offsetBottom >= (window.innerHeight / 2)) {
        this.init()
      }
    },
    init() {
      setTimeout(() => {
        this.link_1 = true
      }, 500)
    }
  }
}
</script>

<style scoped lang="scss">
.content_4 {
  width: 1200px;
  margin: 0 auto;
  height: 700px;
  font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
  position: relative;

  .bg {
    background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/ChessGame/content-4-bg.png");
    width: 281px;
    height: 1352px;
    position: absolute;
    left: -140px;
    top: 0;
  }

  .content-4-1 {
    position: absolute;
    color: #fff;
    left: 60px;
    font-size: 25px;

    .hr {
      width: 200px;
      height: 1px;
      position: absolute;
      border: 0;
      padding-top: 1px;
      left: 140px;
      top: 17px;
      background: linear-gradient(to left, transparent, #d0d0d5);
    }
  }

  .content-4-2 {
    color: #ffffff;
    width: 370px;
    line-height: 30px;
    letter-spacing: 3px;
    position: absolute;
    left: 80px;
    top: 50px;
  }

  @mixin content-4X {
    position: absolute;
    background-size: 100% 100%;
    width: 302px;
    height: 288px;
    .number {
      font-size: 13px;
      color: rgba(0, 0, 0, 0.50);
      text-shadow: 0 2px 11px rgba(0, 0, 0, 0.5);
      position: absolute;
      left: 20px;
      top: 25px;
      font-weight: bold;
    }
    .title {
      font-size: 16px;
      color: #000;
      position: absolute;
      left: 20px;
      top: 55px;
    }
    .img {
      width: 200px;
      height: 148px;
      background-size: 100% 100%;
      position: absolute;
      left: 80px;
      top: 120px;
    }

    .block{
      border-radius: 10px;
      background-color: #fff;
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: 2s all;
      left: -100%;
    }
    .open{
      opacity: 1;
      left: 0;
    }
  }

  .content-4-3 {
    width: 404px;
    height: 288px;
    background: linear-gradient(180deg, #3E75E4 0%, #7C5FD7 100%);
    border-radius: 13px;
    position: absolute;
    left: 640px;
    top: 250px;

    .content-4-3-1 {
      @include content-4X;
      position: absolute;
      left: -40px;
      top: -200px;

      .img {
        background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/ChessGame/content-4-3-1.png");
      }
    }

    .content-4-3-2 {
      @include content-4X;
      position: absolute;
      left: 275px;
      top: -70px;

      .img {
        background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/ChessGame/content-4-3-2.png");
      }
    }

    .content-4-3-3 {
      @include content-4X;
      position: absolute;
      left: -40px;
      top: 100px;

      .img {
        background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/ChessGame/content-4-3-3.png");
      }
    }
  }


}
</style>