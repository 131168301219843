<template>
  <div class="content_10">
    <div class="content-10-1">
      INCUBATION SUPPORT
    </div>
    <div class="content-10-2">
      孵化支持
      <div class="right-hr"></div>
    </div>
    <div class="content-10-3">
      以资本推动科研服务，从微小规模到大型融资均可，贯穿科研转化全程。
    </div>
    <div class="content-10-4">
      <div class="text-bg">
        <div class="text">
          覆盖产学研转化全链路<br>多方位
        </div>
      </div>
    </div>

    <div class="content_10-4-1">
      <div class="bg"></div>
      <div class="block">
        <div class="title">3Q FUND</div>
        <div class="text">基金公司</div>
      </div>
      <div class="block">
        <div class="title">3Q MEDI</div>
        <div class="text">自媒体</div>
      </div>

    </div>

    <div class="content_10-4-2">
      <div class="block">
        <div class="title">1</div>
        <div class="title-1">人才落地政策</div>
      </div>
      <div class="block">
        <div class="title">2</div>
        <div class="title-1">融资服务</div>
      </div>
      <div class="block">
        <div class="title">3</div>
        <div class="title-1">梳理战略和财务</div>
      </div>
      <div class="block">
        <div class="title">4</div>
        <div class="title-1">运营和组建团队</div>
      </div>
      <div class="block">
        <div class="title">5</div>
        <div class="title-1">股权架构设计</div>
      </div>
      <div class="block">
        <div class="title">6</div>
        <div class="title-1">产学研转化的法律服务</div>
      </div>
    </div>

    <div class="content_10-4-3">
      <div class="bg"></div>
      <div class="block">深度介入孵化过程</div>
      <div class="block">全方位服务</div>
      <div class="block">进发创新创业的智慧</div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
.content_10 {
  width: 1200px;
  margin: 0 auto;
  height: 1100px;
  font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
  position: relative;

  .content-10-1 {
    font-size: 16px;
    color: #A3A3A3;
    width: 100%;
  }

  .content-10-2 {
    font-size: 30px;
    color: #fff;
    font-weight: bold;
    margin-top: 20px;
    width: 100%;
    height: 40px;
    position: relative;

    .right-hr {
      width: 1000px;
      height: 1px;
      position: absolute;
      border: 0;
      padding-top: 1px;
      background: linear-gradient(to right, transparent, #d0d0d5);
      right: 0;
      top: 17px;
    }
  }

  .content-10-3 {
    width: 300px;
    color: #fff;
    line-height: 36px;
    position: absolute;
    top: 240px;
    left: 50px;
  }

  .content-10-4 {
    width: 260px;
    height: 140px;
    background: linear-gradient(180deg, #3E75E4 0%, #7C5FD7 100%);
    border-radius: 18px;
    position: absolute;
    left: 20px;
    top: 330px;

    .text-bg {
      background-color: #000;
      width: 255px;
      height: 135px;
      position: absolute;
      left: 2.5px;
      top: 2.5px;
      border-radius: 18px;
      color: #fff;
      text-align: center;
      font-size: 18px;

      .text {
        position: absolute;
        top: 47px;
        left: 13px;
        width: 90%;
      }
    }
  }

  @mixin black_fn {
    width: 230px;
    height: 503px;
    background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/ChessGame/contnent-10-1.png");
    background-size: 100% 100%;
    position: absolute;
    top: 130px;
  }

  .content_10-4-1 {
    @include black_fn;
    left: 380px;
    padding-top: 40px;

    .bg {
      width: 130px;
      height: 130px;
      background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/ChessGame/contnent-10-2.png");
      background-size: 100% 100%;
      margin: 0 auto;
    }

    .block {

      text-align: center;
      margin-top: 20px;
      color: #d0d0d5;

      .title {
        color: #fff;
        font-size: 21px;
      }
    }
  }

  .content_10-4-2 {
    @include black_fn;
    left: 670px;
    padding-top: 20px;
    .block{
      margin-bottom: 40px;
      .title{
        margin: 0 auto;
        width: 25px;
        height: 25px;
        text-align: center;
        border-radius: 50px;
        color: #7C5FD7;
        border: 2px solid #7C5FD7;
        font-size: 18px;
        line-height: 25px;
      }
      .title-1{
        width: 100%;
        text-align: center;
        color: #ffffff;
        font-size: 16px;
        margin-top: 10px;
      }
    }
  }

  .content_10-4-3 {
    @include black_fn;
    left: 960px;
    padding-top: 40px;
    .bg {
      width: 89px;
      height: 104px;
      background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/ChessGame/contnent-10-4.png");
      background-size: 100% 100%;
      margin: 0 auto;
    }
    .block{
      color: #fff;
      margin-top: 40px;
      text-align: center;
      font-size: 18px;
    }
  }
}
</style>