<template>
  <div class="content_9">
    <div class="content-9-1">
      FINANCIAL COMMUNITY
    </div>
    <div class="content-9-2" id="finance">
      金融共同体
      <div class="right-hr"></div>
    </div>
    <div class="left" v-bind:class="{open:link_1}">
      <div class="con-1">
        <div class="title">3QLAB金融共同体拥有</div>
        <div class="main-1">
          <p>元素资本</p>
          <p>上海璞心</p>
          <p>浙江钱和</p>
          <p>……</p>
        </div>
        <div class="main-2">
          覆盖多地、实力雄厚的专业投资队伍， 力求以资本推动科研服务，覆盖产学研转化全程。
        </div>
        <div class="main-3">
          <div class="text-bg">
            <div class="text">
              实力雄厚的专业投资队伍<br>覆盖多地
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="bg"></div>
      <div class="block"  v-bind:class="{open:link_2}">
        <div class="title">元素资本</div>
        <div class="title-1">融资1亿+硬科技产业孵化+创业</div>
        <div class="title-2">致力于打造以“研究机构+天使投资+孵化服务”为一体的硬科技创业生态，使高新技术从“实验室”走出来，解决产业痛点，催生专精特新产业龙头。
        </div>
      </div>
      <div class="block"  v-bind:class="{open:link_3}">
        <div class="title">钱和股权投资</div>
        <div class="title-1">工程师+专业投资者背景</div>
        <div class="title-2">股东包括华为资深专家、风险投资行业资深从业者、CVC投资专家等。
        </div>
      </div>
      <div class="block"  v-bind:class="{open:link_4}">
        <div class="title">璞心资产管理</div>
        <div class="title-1">专注创业投资，资金实力雄厚</div>
        <div class="title-2">华盛控股大股东，投资遍布多个行业企业。
        </div>
      </div>
      <div class="block"  v-bind:class="{open:link_5}">
        <div class="title">青岛鑫成启辰投资中心</div>
        <div class="title-1">资金实力雄厚，已为共同体注入投资2亿元+</div>
        <div class="title-2">华盛控股股东，注资超2亿。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      link_1: false,
      link_2: false,
      link_3: false,
      link_4: false,
      link_5:false
    }
  },
  mounted() {
    window.addEventListener("scroll", this.scrollHandle, true); // 监听 监听元素是否进入/移出可视区域
  },
  methods: {
    scrollHandle() {
      const offset = this.$el.getBoundingClientRect();
      const offsetTop = offset.top;
      const offsetBottom = offset.bottom;

      if (offsetTop <= (window.innerHeight / 4) && offsetBottom >= (window.innerHeight / 2)) {
        this.init()
      }
    },
    init() {
      setTimeout(() => {
        this.link_1 = true
      }, 500)
      setTimeout(() => {
        this.link_2 = true
      }, 1500)
      setTimeout(() => {
        this.link_3 = true
      }, 2000)
      setTimeout(() => {
        this.link_4 = true
      }, 2500)

      setTimeout(() => {
        this.link_4 = true
      }, 3000)
    }
  }
}
</script>

<style scoped lang="scss">
.content_9 {
  width: 1200px;
  margin: 0 auto;
  height: 1100px;
  font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
  position: relative;

  .content-9-1 {
    font-size: 16px;
    color: #A3A3A3;
    width: 100%;
  }

  .content-9-2 {
    font-size: 30px;
    color: #fff;
    font-weight: bold;
    margin-top: 20px;
    width: 100%;
    height: 40px;
    position: relative;

    .right-hr {
      width: 1000px;
      height: 1px;
      position: absolute;
      border: 0;
      padding-top: 1px;
      background: linear-gradient(to right, transparent, #d0d0d5);
      right: 0;
      top: 17px;
    }
  }

  .left {
    height: 763px;
    width: 263px;
    position: absolute;
    left: 50px;
    top: 130px;
    opacity: 0;
    transition: 0.3s all;

    .con-1 {
      width: 100%;
      height: 300px;
      position: absolute;
      left: 0;
      top: 0;

      .title {
        font-size: 17px;
        color: #fff;
        margin-bottom: 15px;
        width: 100%;
      }

      .main-1 {
        color: rgba(124, 95, 215, 1);
        font-size: 20px;
        line-height: 21px;
        font-weight: bold;
        margin-bottom: 15px;
        width: 100%;
      }

      .main-2 {
        width: 100%;
        color: rgba(163, 163, 163, 1);
        line-height: 30px;
        margin-bottom: 30px;
      }

      .main-3 {
        width: 260px;
        height: 140px;
        background: linear-gradient(180deg, #3E75E4 0%, #7C5FD7 100%);
        border-radius: 18px;
        position: relative;

        .text-bg {
          background-color: #000;
          width: 255px;
          height: 135px;
          position: absolute;
          left: 2.5px;
          top: 2.5px;
          border-radius: 18px;
          color: #fff;
          text-align: center;
          font-size: 18px;

          .text {
            position: absolute;
            top: 47px;
            left: 13px;
            width: 90%;
          }
        }
      }
    }
  }

  .right {
    height: 916px;
    width: 700px;
    position: absolute;
    right: 0;
    top: 130px;

    .bg {
      background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/ChessGame/content-9-1.png");
      width: 43px;
      height: 916px;
      position: absolute;
      left: -60px;
      top: 0;
    }

    .block{
      padding-top: 25px;
      margin-bottom: 50px;
      opacity: 0;
      transition: 0.8s all;
      .title{
        color: rgba(124, 95, 215, 1);
        font-size: 18px;
        margin-bottom: 20px;
      }

      .title-1{
        color: #ffffff;
        font-size: 35px;
        margin-bottom: 20px;
      }
      .title-2{
        color: #ffffff;
        font-size: 16px;
        line-height: 37px;
      }
    }
  }
}

.open{
  opacity: 1!important;
}
</style>