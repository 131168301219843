<template>
<div class="content-3">
  <div class="content-3-1">
    有364项实验室相关专利，13项行业资质，省级博士后科研工作站，17000+科学家数据库，20000+实验室客户，客户覆盖高校、政府、疾控、医院、医药企业等。下一步华盛科技将利用区块链技术构建科研人员沟通合作的共享科研社区圈。我们相信，在未来华盛智慧实验室会成为人类产出认知新成果，并将其转为产能的有效媒介。
  </div>
  <div class="content-3-2" v-bind:class="{open:link_1}">
    <div class="content-3-2-1" >
      <div class="block" v-bind:class="{open_block:link_2}"></div>
    </div>
    <div class="content-3-2-2" >
      <div class="block" v-bind:class="{open_block:link_3}"></div>
    </div>
    <div class="content-3-2-3">
      <div class="block" v-bind:class="{open_block:link_4}"></div>
    </div>
  </div>
<!--  <div class="content-3-3">-->
<!--    <div class="title">华盛科技</div>-->
<!--  </div>-->
</div>
</template>

<script>
export default {
  data() {
    return {
      link_1: false,
      link_2: false,
      link_3: false,
      link_4: false
    }
  },
  mounted() {
    window.addEventListener("scroll", this.scrollHandle, true);
  },
  methods: {
    scrollHandle() {
      const offset = this.$el.getBoundingClientRect();
      const offsetTop = offset.top;
      const offsetBottom = offset.bottom;

      if (offsetTop <= (window.innerHeight / 4) && offsetBottom >= (window.innerHeight / 2)) {
        this.init();
      }
    },
    init() {
      setTimeout(() => {
        this.link_1 = true
      }, 500)
      setTimeout(() => {
        this.link_2 = true
      }, 1300)
      setTimeout(() => {
        this.link_3 = true
      }, 1800)
      setTimeout(() => {
        this.link_4 = true
      }, 2300)
    }
  }
}
</script>

<style scoped lang="scss">
.content-3  {
  width: 1200px;
  margin: 0 auto;
  height: 650px;
  font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
  position: relative;
  .content-3-1{
    color: #ffffff;
    width: 370px;
    line-height: 30px;
    letter-spacing:3px;
    position: absolute;
    left: 80px;
  }

  @mixin content-3X{
    position: absolute;
    border-radius: 10px;
    background-size: 100% 100%;
    .block{
      width: 100%;
      height: 100%;
      background-size: 100% 100%;
      border-radius: 13px;
      position: absolute;
      left: -100%;
      opacity: 0;
      transition: 0.5s all;
    }
  }

  .content-3-2{
    width: 310px;
    height: 310px;
    background: linear-gradient(180deg, #3E75E4 0%, #7C5FD7 100%);
    border-radius: 13px;
    position: absolute;
    left: 740px;
    top: 170px;
    opacity: 0;
    transition: 0.5s all;
  }



  .content-3-2-1{
    @include content-3X;
    width: 323px;
    height: 221px;
    left: -80px;
    top: -70px;
    .block{
      background-color: #A3A3A3;
      background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/ChessGame/content-3-2-1.jpg");
    }
  }

  .content-3-2-2{
    @include content-3X;
    width: 166px;
    height: 201px;
    left: -100px;
    top: 200px;
    .block{
      background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/ChessGame/content-3-2-2.jpg");
      background-size: 296px 250px;
      background-position: -60px -40px;
    }
  }

  .content-3-2-3{
    @include content-3X;
    width: 264px;
    height: 178px;
    left: 130px;
    top: 170px;
    .block{
      background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/ChessGame/content-3-2-3.jpg");
    }

  }

  .content-3-3{
    @include content-3X;
    width: 133px;
    height: 133px;
    left: 230px;
    top: 340px;
    border-radius: 0;
    background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/ChessGame/content-3-3.png");
    .title{
      width: 100%;
      text-align: center;
      position: absolute;
      color: #ffffff;
      left: 0;
      top: 150px;
      font-size: 20px;
    }
  }

  .open{
    opacity: 1;
  }

  .open_block{
    opacity: 1!important;
    left: 0!important;
  }
}
</style>
