<template>
<div class="content_8">
  <div class="content-8-1">
    影视IP资源
    <div class="hr"></div>
  </div>
  <div class="content-8-2">
    盛天传媒始终注重IP的开发和积累，拥有强大的影视及IP储备资源——由中英新三国合拍、霍比特人特效团队加盟制作，吴磊、陈乔恩主演的奇幻电影《源·彩虹》；由著名编剧、导演林子指导的四次元爆笑喜剧电影《蜜丝炸弹》等项目已进入后期制作；拥有包括著名惊悚小说作家蔡骏作品《天机》、《生死河》、《地狱变》，鲜橙超人气作品《阿麦从军》等各类优质储备IP超过50部。
  </div>
  <div class="content-8-3">
    <div class="main">
      <div class="swiper">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item,index) in jsonData" :key="index">
              <div class="top">
                <div class="title">《{{item.title}}》</div>
                <div class="time">{{item.time}}年</div>
              </div>
              <div class="bottom">
                <img :src="item.imgurl">
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Swiper from "swiper";
import "swiper/dist/css/swiper.css";
import data from '@/views/ChessGame/ImageData.json'


export default {
  data() {
    return {
      jsonData: data
    }
  },
  mounted() {
    setTimeout(() => {
      this.init()
    }, 500)
  },
  methods:{
    init() {
      new Swiper(".swiper-container", {
        prevButton: '.swiper-button-prev',
        nextButton: '.swiper-button-next',
        width: 300,
        spaceBetween: 50
      });
    }
  }
}
</script>

<style scoped lang="scss">
.content_8{
  width: 1200px;
  margin: 0 auto;
  height: 700px;
  font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
  position: relative;
  .content-8-1{
    position: absolute;
    color: #fff;
    left: 60px;
    font-size: 25px;

    .hr {
      width: 200px;
      height: 1px;
      position: absolute;
      border: 0;
      padding-top: 1px;
      left: 160px;
      top: 17px;
      background: linear-gradient(to left, transparent, #d0d0d5);
    }
  }

  .content-8-2{
    width: 263px;
    color: #fff;
    line-height: 28px;
    font-size: 15.5px;
    position: absolute;
    left: 90px;
    top: 100px;
  }

  .content-8-3{
    height: 440px;
    width: 800px;
    position: absolute;
    right: 0;
    top: 100px;
    .main{
      width: 100%;
      height: 100%;
      .swiper {
        width: 100%;
        height: 230px;
        margin-bottom: 20px;
        position: relative;

        .swiper-wrapper {
          width: 100%;
          height: 100%;

          .swiper-slide {
            width: 300px!important;
            height:450px;
            color: #ffffff;
            background-color: #3E75E4;
            background-size: 100% 100%;
            margin-right: 50px;
            cursor: pointer;


            .top{
              width: 100%;
              height: 70px;
              background-color: #FFFFFF;
              .title{
                color: #000;
                font-size: 15px;
                font-weight: bold;
                width: 100%;
                height: 35px;
                line-height: 35px;
                text-indent: 1rem;

              }
              .time{
                color: #A3A3A3;
                font-size: 13px;
                width: 100%;
                height: 20px;
                line-height: 20px;
                text-indent: 1.5rem;
              }
            }

            .bottom{
              width: 100%;
              height: 380px;
              background-color: #ededed;
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }


        .swiper-button-prev {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l4.2%2C4.2L8.4%2C22l17.8%2C17.8L22%2C44L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") center 50%/50% 50% no-repeat;
          position: absolute;
          left: 0;
          top: 500px;
          border: 1px solid #fff;
        }

        .swiper-button-next {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L5%2C44l-4.2-4.2L18.6%2C22L0.8%2C4.2L5%2C0z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") center 50%/50% 50% no-repeat;
          position: absolute;
          left: 70px;
          top: 500px;
          border: 1px solid #fff;
        }
      }


    }
  }
}
</style>
