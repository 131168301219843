<template>
  <div class="content-1" :style="contentBg">
    <div class="content content-1-1">TRINITY CHESSBOARD</div>
    <div class="content content-1-2">
      <div class="hr left-hr"></div>
      <div class="hr right-hr"></div>
      三一棋局
    </div>
    <div @click="goToBlock('research')" class="content content-1-3">
      科研共同体
      <div class="hr"></div>
    </div>
    <div @click="goToBlock('art')" class="content content-1-4">
      艺术共同体
      <div class="hr"></div>
    </div>
    <div @click="goToBlock('finance')" class="content content-1-5">
      金融共同体
      <div class="hr"></div>
    </div>
  </div>
</template>

<script>
import {image_url } from '../../../const';

export default {
  name: "content-1",
  data() {
    return {
      image_url: image_url,
      contentBg: {
        background: 'url(' + image_url + '/ChessGame/content-1-1.png)',
        backgroundSize: '100% 100%',
      }
    }
  },
  methods: {
    goToBlock(pos) {
      document.getElementById(pos) .scrollIntoView({ behavior: 'smooth' });
    }
  }
}
</script>

<style scoped lang="scss">
.content-1{
  font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
  width: 1200px;
  height: 523px;
  margin: 0 auto;
  // background-image: url("../img/content-1-1.png");
  // background-size: 100% 100%;
  position: relative;
  .content{
    width: 100%;
    text-align: center;
    font-weight: bold;
    position: absolute;
  }
  .content-1-1{
    font-size: 19px;
    color: #A3A3A3;
    top: 90px;
  }

  .content-1-2{
    font-size: 36px;
    top: 140px;
    color: #fff;
    .hr{
      width: 200px;
      height: 1px;
      position: absolute;
      border: 0;
      padding-top: 1px;
    }
    .left-hr{
      background: linear-gradient(to right, transparent, #d0d0d5);
      left: 295px;
      top: 29px;
    }
    .right-hr{
      background: linear-gradient(to left, transparent, #d0d0d5);
      right: 295px;
      top: 29px;
    }
  }
  @mixin content-3-Hr{
    width: 200px;
    height: 0.5px;
    position: absolute;
    left: 50%;
    bottom: -20px;
    border: 0;
    padding-top: 1px;
    background: linear-gradient(to right, transparent, #d0d0d5, transparent);
    margin-left: -100px;
  }
  .content-1-3{
    font-size: 25px;
    top: 220px;
    color: #fff;
    .hr{
      @include content-3-Hr;
      cursor: default;
    }
    cursor: pointer;
  }

  .content-1-4{
    font-size: 25px;
    top: 310px;
    color: #fff;
    .hr{
      @include content-3-Hr;
      cursor: default;
    }
    cursor: pointer;
  }
  .content-1-5{
    font-size: 25px;
    top: 400px;
    color: #fff;
    .hr{
      @include content-3-Hr;
      cursor: default;
    }
    cursor: pointer;
  }
}
</style>